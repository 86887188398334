@import "../styles/shared";

.timebox-toggle-icon {
  cursor: pointer;
  @include mobile {
    height: 6rem;
    width: 6rem;
    margin: 0 4rem;
  }
  @include tablet {
    height: 6rem;
    width: 6rem;
    margin: 0 4.8rem;
  }
  @include desktop {
    height: 8rem;
    width: 8rem;
    margin: 0 8rem;
  }
  @include big {
    height: 12rem;
    width: 12rem;
    margin: 0 8.8rem;
  }
  circle {
    @each $theme, $map in $themes {
      .#{$theme} & {
        stroke: map-get($map, colorClock);
      }
    }
  }
  rect {
    @each $theme, $map in $themes {
      .#{$theme} & {
        fill: map-get($map, colorClock);
      }
    }
  }
  polygon {
    @each $theme, $map in $themes {
      .#{$theme} & {
        fill: map-get($map, colorClock);
      }
    }
  }
}
