$themes: (
  dark:
    (
      background: linear-gradient(202.05deg, #1f2634 0%, #161a24 100%),
      buttonBackground: #1f2634,
      color: #ffffff,
      colorClock: #ffffff,
      colorFocus: #f1f1f1,
      colorClockLabel: rgba(255, 255, 255, 0.8),
      colorSoundItem: rgba(255, 255, 255, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-dark.svg')
    ),
  blue:
    (
      background:
        linear-gradient(
          202.32deg,
          rgba(41, 124, 218, 1) 0%,
          rgba(31, 101, 158, 1) 100%
        ),
      buttonBackground: rgba(41, 124, 218, 1),
      color: #ffffff,
      colorClock: #ffffff,
      colorFocus: #f1f1f1,
      colorClockLabel: rgba(255, 255, 255, 0.8),
      colorSoundItem: rgba(255, 255, 255, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-blue.svg')
    ),
  yellow:
    (
      background: rgba(255, 204, 0, 1),
      buttonBackground: rgba(255, 204, 0, 1),
      color: #111111,
      colorClock: #111111,
      colorFocus: #111111,
      colorClockLabel: rgba(0, 0, 0, 0.8),
      colorSoundItem: rgba(0, 0, 0, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-yellow.svg')
    ),
  pink:
    (
      background:
        linear-gradient(
          151.88deg,
          rgba(229, 97, 76, 1) 0%,
          rgba(198, 48, 79, 1) 100%
        ),
      buttonBackground: rgba(229, 97, 76, 1),
      color: #ffffff,
      colorClock: #ffffff,
      colorFocus: #f1f1f1,
      colorClockLabel: rgba(255, 255, 255, 0.8),
      colorSoundItem: rgba(255, 255, 255, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-red.svg')
    ),
  purple:
    (
      background:
        linear-gradient(
          202.85deg,
          rgba(143, 43, 202, 1) 0%,
          rgba(106, 48, 147, 1) 100%
        ),
      buttonBackground: rgba(143, 43, 202, 1),
      color: #ffffff,
      colorClock: #ffffff,
      colorFocus: #f1f1f1,
      colorClockLabel: rgba(255, 255, 255, 0.8),
      colorSoundItem: rgba(255, 255, 255, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-purple.svg')
    ),
  alarm:
    (
      background: #f44336,
      buttonBackground: #f44336,
      color: #ffffff,
      colorClock: #ffffff,
      colorFocus: #f1f1f1,
      colorClockLabel: rgba(255, 255, 255, 0.8),
      colorSoundItem: rgba(255, 255, 255, 0.7),
      colorSwitcher: center no-repeat url('../images/dots-purple.svg')
    )
);
