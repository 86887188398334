@import "../../styles/shared";

.picker-container {
  z-index: 10001;
  width: 100%;

  /* disable text selection via swiping */
  user-select: none;

  /* disable browser handling of all panning and zooming gestures */
  touch-action: none;

  outline: none;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .picker-inner {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 2rem;
    font-size: 1.2em;
    mask-image: linear-gradient(
      to top,
      transparent,
      transparent 15%,
      rgba(white, 0.3) 35%,
      white 50%,
      rgba(white, 0.3) 65%,
      transparent 85%,
      transparent
    );
    .picker-label {
      font-family: "Roboto Condensed";
      position: absolute;
      display: flex;
      align-items: center;

      /* disable text selection via swiping */
      user-select: none;

      /* disable browser handling of all panning and zooming gestures */
      touch-action: none;

      @include mobile {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-top: 21.5rem;
        margin-left: 6rem;
      }
      @include tablet {
        font-size: 2.3rem;
        line-height: 2.3rem;
        margin-top: 26.7rem;
        margin-left: 3.2rem;
      }
      @include desktop {
        font-size: 2.3rem;
        line-height: 2.3rem;
        margin-top: 32.2rem;
        margin-left: 5rem;
      }
      @include big {
        font-size: 3.5rem;
        line-height: 3.5rem;
        margin-top: 47.4rem;
        margin-left: 7rem;
      }
    }

    .picker-column-container {
      display: flex;
      justify-content: center;
      flex: 1 1;
    }
  }

  .picker-highlight {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    pointer-events: none;

    @each $theme, $map in $themes {
      .#{$theme} & {
        border-bottom: 1px rgba(map-get($map, colorClock), 0.5) solid;
        border-top: 1px rgba(map-get($map, colorClock), 0.5) solid;
      }
    }
  }
}
