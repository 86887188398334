@import "../styles/shared";

.clock-value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clock-style {
  font-family: "Roboto Condensed";
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorClock);
    }
  }
  @include mobile {
    font-size: 12.8rem;
    line-height: 12.8rem;
  }
  @include tablet {
    font-size: 22rem;
    line-height: 22rem;
  }
  @include desktop {
    font-size: 29.2rem;
    line-height: 29.2rem;
  }
  @include big {
    font-size: 38.8rem;
    line-height: 38.8rem;
  }
}

.clock-style-colon {
  font-family: "Roboto Condensed";
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorClock);
    }
  }
  @include mobile {
    font-size: 9.6rem;
    line-height: 12.8rem;
  }
  @include tablet {
    font-size: 16.5rem;
    line-height: 22rem;
  }
  @include desktop {
    font-size: 21.9rem;
    line-height: 29.2rem;
  }
  @include big {
    font-size: 29.1rem;
    line-height: 38.8rem;
  }
}

.clock-label {
  text-transform: capitalize;
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorClockLabel);
    }
  }
  @include mobile {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  @include tablet {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin-top: -1.6rem;
  }
  @include desktop {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-top: -1.6rem;
  }
  @include big {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: -2rem;
  }
}
