@import "../styles/shared";

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  left: 0;
  top: 0;
  @each $theme, $map in $themes {
    .#{$theme} & {
      background: map-get($map, background);
    }
  }
  overflow: scroll;
  transition: 0.5s;
}

.sound-item {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 3.2rem;
}

.sound-item-value {
  display: none;
}

.sound-item-value + label {
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorSoundItem);
    }
  }
  font-weight: 300;
  cursor: pointer;
}

.sound-item-value:checked + label {
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorFocus);
    }
  }
  font-weight: 600;
}

.overlay-close-container {
  margin-top: 6.4rem;
  margin-bottom: 3.2rem;
}

.overlay-close {
  cursor: pointer;
  height: 3.6rem;
  .overlay-close-g {
    @each $theme, $map in $themes {
      .#{$theme} & {
        fill: map-get($map, color);
      }
    }
  }
}

.overlay-content {
  text-align: center;
}

.sound-selection-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include mobile {
    font-size: 1.5rem;
    line-height: 1.6rem;
    height: 1.6rem;
  }
  @include tablet {
    font-size: 1.5rem;
    line-height: 1.6rem;
    height: 1.6rem;
  }
  @include desktop {
    font-size: 1.5rem;
    line-height: 1.6rem;
    height: 1.6rem;
  }
  @include big {
    font-size: 1.8rem;
    line-height: 2.4rem;
    height: 2.4rem;
  }
  .sound-selection-toggle-icon {
    margin-left: 0.8rem;
    height: 0.6rem;
    g {
      @each $theme, $map in $themes {
        .#{$theme} & {
          fill: map-get($map, color);
        }
      }
    }
  }
}

.color-focus:hover,
.color-focus:focus {
  transition: 0.3s;
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, colorFocus);
    }
  }
}
