@import "../styles/shared";

.logo-ia {
  @include mobile {
    height: 1.4rem;
  }
  @include tablet {
    height: 1.4rem;
  }
  @include desktop {
    height: 1.4rem;
  }
  @include big {
    height: 1.8rem;
  }
  @each $theme, $map in $themes {
    .#{$theme} & g {
      fill: map-get($map, colorClock);
    }
  }
}
