@import "../../styles/shared";

.time-picker-container {
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 5px 24px 0 rgba(0, 0, 0, 0.5);
  color: #000000;
  font-family: "Roboto Condensed";

  @include mobile {
    width: 58.1rem;
    height: 53.6rem;
    padding: 1.6rem 2.4rem;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @include tablet {
    width: 58.1rem;
    height: 53.6rem;
    padding: 1.6rem 2.4rem;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @include desktop {
    width: 58.1rem;
    height: 53.6rem;
    padding: 1.6rem 2.4rem;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @include big {
    width: 87.1rem;
    height: 81.9rem;
    padding: 2.4rem 3.6rem;
    font-size: 3rem;
    line-height: 3.6rem;
  }
}

.values-container {
  width: 100%;
  display: flex;
}

.minutes-container {
  width: 85%;
  border-right: 1px solid #d8d8d8;
  @include mobile {
    margin-top: -1.6rem;
    padding-top: 1.6rem;
  }
  @include tablet {
    margin-top: -1.6rem;
    padding-top: 1.6rem;
  }
  @include desktop {
    margin-top: -1.6rem;
    padding-top: 1.6rem;
  }
  @include big {
    margin-top: -2.4rem;
    padding-top: 2.4rem;
  }
}

.seconds-container {
  max-width: 17%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.minutes-header {
  width: 100%;
}

.minutes-values {
  width: 100%;
  @include mobile {
    margin-left: -1.2rem;
  }
  @include tablet {
    margin-left: -1.2rem;
  }
  @include desktop {
    margin-left: -1.2rem;
  }
  @include big {
    margin-left: -1.8rem;
  }
}

.seconds-values {
  width: 100%;
  @include mobile {
    margin-right: -1.2rem;
    margin-left: 1.2rem;
  }
  @include tablet {
    margin-right: -1.2rem;
    margin-left: 1.2rem;
  }
  @include desktop {
    margin-right: -1.2rem;
    margin-left: 1.2rem;
  }
  @include big {
    margin-right: -1.8rem;
    margin-left: 1.8rem;
  }
}

.values {
  display: flex;
  flex-wrap: wrap;
  @include mobile {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  @include tablet {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  @include desktop {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  @include big {
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  }
}

.footer-container {
  border-top: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  @include mobile {
    padding: 1.6rem 1.2rem;
    margin: 0 -2.4rem;
  }
  @include tablet {
    padding: 1.6rem 1.2rem;
    margin: 0 -2.4rem;
  }
  @include desktop {
    padding: 1.6rem 1.2rem;
    margin: 0 -2.4rem;
  }
  @include big {
    padding: 2.4rem 1.8rem;
    margin: 0 -1.8rem;
  }
}

.button-footer {
  width: 45%;
  padding: 0;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  font-family: "Roboto Condensed";

  @include mobile {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include tablet {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include desktop {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include big {
    font-size: 3rem;
    line-height: 3.6rem;
    padding: 1.8rem;
  }
}

.button-secondary {
  @each $theme, $map in $themes {
    .#{$theme} & {
      border: 1px solid map-get($map, buttonBackground);
    }
  }
}

.button-primary {
  @each $theme, $map in $themes {
    .#{$theme} & {
      border: 1px solid map-get($map, buttonBackground);
      color: map-get($map, color);
      background-color: map-get($map, buttonBackground);
    }
  }
}
