@import "../../styles/shared";

.picker-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 45%;
  font-family: "Roboto Condensed";

  @include mobile {
    font-size: 3.6rem;
  }
  @include tablet {
    font-size: 4.8rem;
  }
  @include desktop {
    font-size: 6rem;
  }
  @include big {
    font-size: 9rem;
  }
}
