@import "../../styles/shared";

button.button-time-value {
  padding: 0;
  color: #666666;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  font-family: "Roboto Condensed";

  @include mobile {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include tablet {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include desktop {
    font-size: 2rem;
    line-height: 2rem;
    padding: 1.2rem;
  }
  @include big {
    font-size: 3rem;
    line-height: 3rem;
    padding: 1.8rem;
  }
}

button.button-time-value:hover {
  border-radius: 2px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, color);
      background-color: rgba(
        $color: map-get($map, buttonBackground),
        $alpha: 0.5
      );
    }
  }
}

button.button-time-value.active,
button.button-time-value:active {
  border-radius: 2px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  @each $theme, $map in $themes {
    .#{$theme} & {
      color: map-get($map, color);
      background-color: map-get($map, buttonBackground);
      border-color: map-get($map, buttonBackground);
    }
  }
}
