@import "../styles/shared";

.app {
  height: 100%;
  width: 100%;
}

@each $theme, $map in $themes {
  .#{$theme} {
    background: map-get($map, background);
    color: map-get($map, color);
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include mobile {
    padding: 2.4rem 2rem 0 2rem;
  }
  @include tablet {
    padding: 3.4rem 3.2rem 0 3.2rem;
  }
  @include desktop {
    padding: 3.4rem 3.2rem 0 3.2rem;
  }
  @include big {
    padding: 3.4rem 3.2rem 0 3.2rem;
  }
}

.clock-container {
  position: relative;
  top: 50%;
  transform: translateY(-65%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.timebox-toggle-container {
  position: absolute;
  bottom: 7.6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-switch {
  position: absolute;
  bottom: 3.2rem;
  height: 1.2rem;
  width: 100%;
  cursor: pointer;

  @each $theme, $map in $themes {
    .#{$theme} & {
      background: map-get($map, colorSwitcher);
    }
  }
}
