.picker-column {
  position: relative;
  max-height: 100%;
  width: 100%;
  overflow: hidden;

  /* disable text selection via swiping */
  user-select: none;

  /* disable browser handling of all panning and zooming gestures */
  touch-action: none;
}
