.timebox-event-emitter {
  height: 100%;
  width: 100%;

  /* disable text selection via swiping */
  user-select: none;

  /* disable browser handling of all panning and zooming gestures */
  touch-action: none;

  outline: none;
}
