.timebox-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: red;
  background-color: rgba(#000000, $alpha: 0.2);
}
